import { createSlice } from '@reduxjs/toolkit';
import questionConfig from '../../components/Questions/config';

import {
  getAllThemes,
  getAllUsers,
  getCurrentTheme,
  getExternalPanel,
  getSelectedExternalPanel,
  getURL,
  setdays,
} from '../asyncActions/questionBuilder';
import {
  getQuestionList,
  getSurveyDetails,
  getQuestionDetails,
  createNewQuestion,
  updateQuestionDetail,
  deleteQuestion,
  addQuestionText,
  updateQuestionText,
  deleteQuestionText,
  changeQuestionOrder,
  getPreviewToken,
  invalidatePreviewToken,
  updateJumpCondition,
  removeCondition,
  addLogicExpression,
  removeLogicExpression,
  addImageOptions,
  addMediaAttachment,
  removeMediaAttachment,
  getMessageText,
  getSectionList,
} from '../asyncActions';
import { userObjectType } from './Types';
import React from 'react';
import { DEFAULT_LANGUAGE_CODE } from '../../components/Questions/QuestionCreate/constant';
import {
  QUADRANT_CHART_QUESTION,
  TERMINATE_SCREEN,
  THANKYOU_SCREEN,
  WELCOME_SCREEN,
} from '@/components/Questions/constant';
import { toast } from 'react-toastify';

export const generateRandomNumber = () => {
  const val = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000;
  return val;
};

export enum Media {
  image = 'image',
  video = 'video',
  audio = 'audio',
}

enum logicType {
  and = 'and',
  or = 'or',
}

export type expressionLogicType = {
  logic?: logicType;
  question?: number;
  condition?: string;
  value?: string | number;
};

type conditionLoginType = {
  jump: number | null;
  list: Array<expressionLogicType>;
};

export type conditionObjectType = {
  status: boolean;
  list: Array<conditionLoginType>;
};

type ansGrpConditionType = {
  answer: string;
  list: Array<expressionLogicType>;
};

export type ansValueMapItemType = {
  variable: string;
  feQuestionId: string;
  sectionId: number;
};

export type answerObjectType = {
  min?: number;
  max?: number;
  showCurrentTime?: boolean;
  randomize?: boolean;
  isReversePiping?: boolean;
  isSearchSelect?: boolean;
  isNoneOption?: boolean;
  pipeQuestionId?: null | number | string;
  pipeQuestionIdList?: Array<number | string>;
  rowPipeQuestionId?: null | number | string;
  rowPipeQuestionIdList?: Array<number | string>;
  isRowReverse?: boolean;
  columnPipeQuestionId?: null | number | string;
  columnPipeQuestionIdList?: Array<number | string>;
  isColumnReverse?: boolean;
  imageSizeBig?: boolean;
  orderBelow?: boolean;
  showTextBox?: boolean;
  textBelow?: boolean;
  imageDescribe?: boolean;
  textBoxNumber?: string | number;
  textBoxPlaceHolder?: string;
  routerResumeSectionId: number;
  textTitle?: string;
  multiSelect?: boolean;
  captionRequired?: boolean;
  matrixRandomize?: {
    row: boolean;
    column: boolean;
  };
  fullscreenView?: boolean;
  answerAll?: boolean;
  icon?: string;
  minimumAnswers?: number;
  list?: Array<ansGrpConditionType>;
  ansValueMap?: Array<ansValueMapItemType>;
  allowFileUpload?: boolean;
  displayFileType?: string;
  allowCapture?: string;
  allowUpload?: string;
  link?: string;
  questionFeqId?: number;
  additionalQuestionFeqId?: number;
  sectionId?: number;
  additionalQuestionSectionId?: number;
  caption?: boolean;
};

export type languageTextTypeObject = {
  id: number | null;
  parentId: number | null;
  parentText: string | null;
  orderId: number;
  text: string | null;
  textType: string;
  extraInfo?: string | null;
  languageId: string;
  batchId: number;
  questionId: number;
  prefix?: React.ReactNode | null;
  suffix?: React.ReactNode | null;
  placeholder?: string;
  caption?: string | null;
  answerType?: string | null;
};

export type languageTextType = {
  id: number | null;
  orderId: number;
  parentId: number | null;
  parentText: string | null;
  text: string | null;
  type?: string;
  url?: string;
  extraInfo?: string | null;
  cityId?: number;
  caption?: string;
};

export type languageObjectType = {
  language: string;
  languageCode: string;
  option?: Array<languageTextType>;
  options?: Array<languageTextType>;
  columnText?: Array<languageTextType>;
  rowText?: Array<languageTextType>;
  rowTextSub?: Array<languageTextType>;
  optionRow?: Array<languageTextType>;
  optionColumn?: Array<languageTextType>;
  optionRowSub?: Array<languageTextType>;
  rowOne?: Array<languageTextType>;
  rowOneText?: Array<languageTextType>;
  rowTwo?: Array<languageTextType>;
  rowTwoText?: Array<languageTextType>;
  rowThree?: Array<languageTextType>;
  rowThreeText?: Array<languageTextType>;
  rowFour?: Array<languageTextType>;
  rowFourText?: Array<languageTextType>;
  rowFive?: Array<languageTextType>;
  rowFiveText?: Array<languageTextType>;
  rowSix?: Array<languageTextType>;
  rowSixText?: Array<languageTextType>;
  question?: languageTextType;
  subQuestion?: languageTextType;
};

export type questionObjectType = {
  id: number;
  feqId: number;
  option: string[];
  questionSequence: number;
  questionObject?: answerObjectType;
  extraInfo?: object | null;
  orderId: number;
  questionText: string;
  description?: string;
  attachmentType?: Media | null;
  attachmentURL?: string;
  attachmentFile?: File | Blob | null;
  answerType: keyof typeof questionConfig;
  condition?: conditionObjectType;
  languages?: Array<languageObjectType>;
  allFetched?: Boolean;
  section: number;
  sectionId: number;
  sectionOrderId: number;
  previousQuestionIds?: Array<string>;
};

export type questionListObjectType = {
  id?: number;
  feqId: number;
  orderId: number;
  answerType: keyof typeof questionConfig;
  section: number;
  questionText?: string;
};

type languageListType = {
  displayName: string;
  languageId: string;
};

type allQuestionObjectType = {
  [key: number]: questionObjectType;
};

export type apiQuestionListType = {
  answerType: string;
  feqId: number;
  id: null;
  option: string[];
  orderId: number;
  questionText: string;
  themeJobStatus?: null | 'pending' | 'completed' | 'failed';
  summaryJobStatus?: null | 'pending' | 'completed' | 'failed';
  sectionOrderId?: number;
};
type entireQuestionListObj = {
  answerType: string;
  feqId: number;
  id: number;
  option: string[];
  orderId: number;
  questionText: string;
  section: number;
};

type sectionListObj = {
  id: number;
  isOn: boolean;
  type: string;
  orderId: number;
  randomize: any;
  randomizeGroup: any;
  sectionName: string;
};

interface surveyDetailData {
  categoryId: null | string | number;
  id: number;
  industryId: null | string | number;
  taxonomyId: null | string | number;
  themeId: null | string | number;
  themeType: string;
  title: string;
  updatedAt: string;
  languageIds: Array<languageListType>;
  projectId: number;
  hasSection: boolean;
  researchSubType?: string;
}
export interface surveyData {
  surveyId: number;
  title: string;
  details: surveyDetailData;
  messages: {
    [WELCOME_SCREEN]: Array<languageObjectType>;
    [TERMINATE_SCREEN]: Array<languageObjectType>;
    [THANKYOU_SCREEN]: Array<languageObjectType>;
  };
  selected: string | number;
  selectedLanguage: string;
  questionList: Array<questionListObjectType>;
  questionListFulfilled: boolean;
  questionObject: allQuestionObjectType;
  languageIds: Array<languageListType>;
  loading: boolean;
  urlLoading: boolean;
  isValid: boolean;
  members: Array<userObjectType>;
  questionListAPIResponse: Array<apiQuestionListType>;
  themes: Array<object>;
  currentTheme: number;
  previewToken: string;
  isSaving: boolean | null;
  urlData: URLObjectType;
  externalPanel: Array<externalPanelObjectType>;
  selectedExternalPanel: number;
  selectedExternalPanelText: string;
  hasSection: boolean;
  entireQuestionList: Array<entireQuestionListObj>;
  sectionListAPIResponse: Array<sectionListObj>;
  showSection: boolean;
  currentSection: number;
}

type URLObjectType = {
  url: string;
  endDate: string;
  appUrl: string;
};

type externalPanelObjectType = {
  batchId: number;
  complete: string;
  disqualification: string;
  overQuota: string;
  qualityDisqualification: string;
  panelName: string;
  externalPanelText: string;
  id: number;
};

type addQuestionType = {
  type: keyof typeof questionConfig;
  index: number;
};

const initialSurveyDetail: surveyDetailData = {
  categoryId: null,
  id: -1,
  industryId: null,
  taxonomyId: null,
  themeId: null,
  themeType: '',
  title: '',
  updatedAt: '',
  languageIds: [],
  projectId: 0,
};

const initialQuestionObjectState: questionObjectType = {
  feqId: -1,
  answerType: '',
  orderId: 0,
  questionSequence: 0,
  id: -1,
  option: [],
  questionObject: {},
  extraInfo: {},
  condition: {
    list: [],
    status: false,
  },
  questionText: '',
  description: '',
  attachmentType: null,
  attachmentFile: null,
  attachmentURL: '',
  allFetched: false,
  section: 1,
};

export const initialState: surveyData = {
  surveyId: -1,
  selected: -2,
  selectedLanguage: DEFAULT_LANGUAGE_CODE,
  title: '',
  details: {
    ...initialSurveyDetail,
  },
  messages: {
    [WELCOME_SCREEN]: [],
    [THANKYOU_SCREEN]: [],
    [TERMINATE_SCREEN]: [],
  },
  questionList: [],
  questionListFulfilled: false,
  questionObject: {},
  languageIds: [],
  loading: true,
  isValid: true,
  members: [],
  questionListAPIResponse: [],
  themes: [],
  currentTheme: -1,
  previewToken: '',
  isSaving: null,
  urlLoading: false,
  urlData: {
    url: '',
    endDate: '',
    appUrl: '',
  },
  externalPanel: [],
  selectedExternalPanel: -1,
  selectedExternalPanelText: '',
  hasSection: false,
  sectionListAPIResponse: [],
  showSection: false,
  currentSection: -1,
};

export const SurveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setPreviousQuestionIds: (state: surveyData, action) => {
      const { feqId } = action.payload;
      state.questionObject[feqId].previousQuestionIds = [];
    },
    setCurrentSection: (state: surveyData, action) => {
      state.currentSection = action.payload;
    },
    selectQuestionScreen: (state: surveyData, action) => {
      const { id } = action.payload;
      state.selected = id;
    },
    selectLangauge: (state: surveyData, action) => {
      const { langaugeCode } = action.payload;
      state.selectedLanguage = langaugeCode;
    },
    resetSurveyPreview: (state: surveyData) => {
      state.selected = 'welcome';
    },
    surveyCleanup: () => initialState,
    addQuestion: (state: surveyData, action) => {
      state.isSaving = true;
      // add new question
      const { type, index }: addQuestionType = action.payload;
      const feqId = generateRandomNumber();
      const quesObject: questionObjectType = {
        ...initialQuestionObjectState,
        feqId,
        answerType: type,
        orderId: index,
        allFetched: true,
      };
      state.selected = feqId;
      state.questionObject[feqId] = quesObject;
      if (index === state.questionList.length) {
        state.questionList.push({ ...quesObject });
      } else {
        const newQuestionList = [];
        for (
          let qIndex = 0;
          qIndex < state.questionList.length + 1;
          qIndex += 1
        ) {
          if (qIndex < index) {
            const ques = state.questionList[qIndex];
            newQuestionList.push(ques);
          } else if (qIndex === index) {
            newQuestionList.push({ ...quesObject });
          } else {
            const ques = state.questionList[qIndex - 1];
            newQuestionList.push({
              ...ques,
              orderId: qIndex,
            });
          }
        }
        state.questionList = newQuestionList;
        state.isSaving = true;
      }
    },
    removeQuestion: (state: surveyData, action) => {
      const { feqId } = action.payload;
      delete state.questionObject[feqId];
      const newQuesList = state.questionList.filter(
        (ques) => ques.feqId !== feqId,
      );
      state.questionList = newQuesList;
      state.selected = -1;
    },
    updateQuestionTexts: (state: surveyData, action) => {
      const { feqId, questionText, description } = action.payload;
      state.questionObject[feqId].questionText = questionText;
      state.questionObject[feqId].description = description;
    },
    updateQuestionMedia: (state: surveyData, action) => {
      const { feqId, attachmentType, attachmentURL, attachmentFile } =
        action.payload;
      state.questionObject[feqId].attachmentType = attachmentType;
      state.questionObject[feqId].attachmentURL = attachmentURL;
      state.questionObject[feqId].attachmentFile = attachmentFile;
    },
    updateQuestionObject: (state: surveyData, action) => {
      const { questionObject, feqId } = action.payload;
      state.questionObject[feqId].questionObject = {
        ...state.questionObject[feqId].questionObject,
        ...questionObject,
      };
    },
    setisValidId: (state: surveyData) => {
      state.isValid = true;
    },
    addNewLogicCondition: (state: surveyData, action) => {
      const { feqId, addAsQuestionObject } = action.payload;
      const emptyList = {
        list: [],
        status: false,
      };
      const undefinedList = {
        list: [
          {
            question: undefined,
            condition: undefined,
            value: undefined,
          },
        ],
        jump: null,
      };
      if (addAsQuestionObject) {
        undefinedList.answer = '';
        delete undefinedList.jump;
        delete emptyList.status;
        if (state.questionObject[feqId].questionObject?.list === undefined) {
          state.questionObject[feqId].questionObject = emptyList;
        }
        state.questionObject[feqId].questionObject?.list.push(undefinedList);
      } else {
        if (state.questionObject[feqId].condition?.list === undefined) {
          state.questionObject[feqId].condition = emptyList;
        }
        state.questionObject[feqId].condition?.list.push(undefinedList);
      }
    },
    addNewLogicExpression: (state: surveyData, action) => {
      const { feqId, conditionIndex, addAsQuestionObject } = action.payload;
      let len = 0;
      if (addAsQuestionObject) {
        len = state.questionObject[feqId].questionObject?.list?.length;
      } else {
        len = state.questionObject[feqId].condition?.list?.length;
      }
      if (len > 0) {
        if (addAsQuestionObject) {
          state.questionObject[feqId].questionObject?.list[
            conditionIndex
          ].list.push({
            logic: 'and',
          });
        } else {
          state.questionObject[feqId].condition?.list[conditionIndex].list.push(
            {
              logic: 'and',
            },
          );
        }
      }
      if (addAsQuestionObject) {
        state.questionObject[feqId].questionObject?.list[
          conditionIndex
        ].list.push({
          question: undefined,
          condition: undefined,
          value: undefined,
        });
      } else {
        state.questionObject[feqId].condition?.list[conditionIndex].list.push({
          question: undefined,
          condition: undefined,
          value: undefined,
        });
      }
    },
    updateAnsValueMapInText: (state: surveyData, action) => {
      const { feqId, ansValueMap } = action.payload;
      if (state.questionObject[feqId].questionObject) {
        state.questionObject[feqId].questionObject.ansValueMap = ansValueMap;
      } else {
        state.questionObject[feqId].questionObject = {
          ansValueMap: ansValueMap,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionList.pending, (state: surveyData) => {
        state.loading = true;
      })
      .addCase(getQuestionList.fulfilled, (state: surveyData, action) => {
        state.loading = false;
        const list = action?.payload?.value || [];
        const questList: Array<questionListObjectType> = [];
        const questObject: allQuestionObjectType = {};

        list.forEach((ques: questionObjectType) => {
          let feId = ques.feqId || generateRandomNumber();
          questList.push({
            id: ques.id,
            feqId: feId,
            answerType: ques.answerType,
            orderId: ques.orderId,
            section: ques.sectionId,
            sectionOrderId: ques.sectionOrderId,
            questionText: ques.questionText,
          });
          let prevObj = {};
          if (feId in state.questionObject) {
            prevObj = state.questionObject[feId];
          }
          questObject[feId] = {
            ...prevObj,
            ...ques,
          };
        });
        state.questionList = questList;
        state.entireQuestionList = action.payload.value;

        state.questionObject = questObject;
        state.languageIds = action.payload.languageIds;
        state.title = action.payload.title;
        state.questionListAPIResponse = list;
        state.isValid = true;
        state.questionListFulfilled = true;
      })
      .addCase(getQuestionList.rejected, (state: surveyData) => {
        state.loading = false;
        state.isValid = false;
        state.questionListFulfilled = false;
        state = initialState;
      })
      .addCase(getSectionList.pending, (state: surveyData) => {
        state.loading = true;
      })
      .addCase(getSectionList.fulfilled, (state: surveyData, action) => {
        state.loading = false;
        state.sectionListAPIResponse = action.payload?.value;
      })
      .addCase(getSectionList.rejected, (state: surveyData, action) => {
        state.loading = false;
        state.sectionListAPIResponse = [];
      })
      .addCase(getAllUsers.pending, (state: surveyData) => {
        state.loading = true;
      })
      .addCase(getAllUsers.fulfilled, (state: surveyData, action) => {
        state.loading = false;
        state.members = action.payload?.value || [];
      })
      .addCase(getAllUsers.rejected, (state: surveyData) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getCurrentTheme.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentTheme.fulfilled, (state, action) => {
        state.loading = false;
        state.currentTheme = action.payload.data.value.themeId;
      })
      .addCase(getCurrentTheme.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getExternalPanel.pending, (state) => {
        state.loading = true;
      })
      .addCase(getExternalPanel.fulfilled, (state, action) => {
        state.loading = false;
        state.externalPanel = action.payload.data.value;
      })
      .addCase(getExternalPanel.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getSelectedExternalPanel.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSelectedExternalPanel.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedExternalPanel = action.payload.data.value.externalPanelId;
        state.selectedExternalPanelText =
          action.payload.data.value.externalPanelText;
        state.languageIds = action.payload.data.value.languageIds;
      })
      .addCase(getSelectedExternalPanel.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(setdays.pending, (state) => {
        state.urlLoading = true;
      })
      .addCase(setdays.fulfilled, (state, action) => {
        state.urlLoading = false;
        toast.success('URL Generated Successfully', {
          autoClose: 1500,
        });
      })
      .addCase(setdays.rejected, (state, payload) => {
        state.urlLoading = false;
        toast.error('Failed to Generate Link', {
          autoClose: 1500,
        });
      })
      .addCase(getURL.pending, (state) => {
        state.urlLoading = true;
      })
      .addCase(getURL.fulfilled, (state, action) => {
        state.urlLoading = false;
        state.urlData = action.payload.data.value;
        // if (action.payload.data.value.url) {
        //   const url = new URL(action.payload.data.value.url);
        //   const token = url.searchParams.get('token');
        //   if (token) {
        //     state.previewToken = token;
        //   }
        // }
      })
      .addCase(getURL.rejected, (state, payload) => {
        state.urlLoading = false;
        state = initialState;
      })
      .addCase(getAllThemes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllThemes.fulfilled, (state, action) => {
        state.loading = false;
        state.themes = action.payload.data.value;
      })
      .addCase(getAllThemes.rejected, (state, payload) => {
        state.loading = false;
        state = initialState;
      })
      .addCase(getSurveyDetails.pending, (state: surveyData) => {
        // state.loading = true;
        // do nothing
      })
      .addCase(getSurveyDetails.fulfilled, (state: surveyData, action) => {
        state.details = action.payload.value;
        state.surveyId = action.payload.value.id;
        state.currentTheme = action.payload.value?.themeId || -1;
        state.hasSection = action.payload.value.hasSection;
        state.showSection =
          action.payload.value.researchSubType === 'video_diary' ||
          action.payload.value.researchSubType === 'excel' ||
          action.payload.value.researchSubType === 'product_testing';
      })
      .addCase(getSurveyDetails.rejected, (state: surveyData) => {
        state.details = initialSurveyDetail;
      })
      .addCase(getQuestionDetails.pending, (state: surveyData) => {
        // state.loading = true;
        // do nothing
      })
      .addCase(getQuestionDetails.fulfilled, (state: surveyData, action) => {
        const feqId = action.payload.data.feqId;

        if (feqId) {
          state.questionObject[feqId] = {
            ...state.questionObject[feqId],
            // ...action.payload,
            orderId: action.payload.data.questionSequence,
            languages: action.payload.data.languages,
            allFetched: true,
          };
          Object.keys(action.payload.data).forEach((key) => {
            if (action.payload.data[key]) {
              state.questionObject[feqId][key] = action.payload.data[key];
            }
          });
        } else if (action.payload.pageType) {
          state.messages[action.payload.pageType] = [
            ...action.payload.data.options,
          ];
        }
      })
      .addCase(getQuestionDetails.rejected, (state: surveyData) => {
        // state.details = initialSurveyDetail;
      })
      .addCase(createNewQuestion.pending, (state: surveyData) => {
        state.loading = true;
        state.isSaving = true;
        // do nothing
      })
      .addCase(getPreviewToken.fulfilled, (state: surveyData, action) => {
        state.previewToken = action.payload.previewToken || '';
      })
      .addCase(invalidatePreviewToken.rejected, (state: surveyData) => {
        state.previewToken = '';
      })
      .addCase(invalidatePreviewToken.fulfilled, (state: surveyData) => {
        state.previewToken = '';
      })
      .addCase(createNewQuestion.fulfilled, (state: surveyData, action) => {
        state.loading = false;
        const { batchId, answerType, orderId, feqId, id } = action.payload;
        const quesObject: questionObjectType = {
          ...initialQuestionObjectState,
          id: parseInt(id),
          feqId,
          answerType,
          orderId,
          questionSequence: orderId,
          allFetched: true,
        };
        const questListObject: questionListObjectType = {
          id: parseInt(id),
          feqId,
          answerType,
          orderId,
        };
        state.selected = feqId;
        state.questionObject[feqId] = quesObject;
        if (orderId - 1 === state.questionList.length) {
          state.questionList.push({ ...questListObject });
        } else {
          const newQuestionList = [];
          for (
            let qIndex = 0;
            qIndex < state.questionList.length + 1;
            qIndex += 1
          ) {
            if (qIndex < orderId - 1) {
              const ques = state.questionList[qIndex];
              newQuestionList.push(ques);
            } else if (qIndex === orderId - 1) {
              newQuestionList.push({ ...questListObject });
            } else {
              const ques = state.questionList[qIndex - 1];
              newQuestionList.push({
                ...ques,
              });
              state.questionObject[ques.feqId].orderId = qIndex;
            }
          }
          state.questionList = newQuestionList;
        }
        state.isSaving = false;
      })
      .addCase(createNewQuestion.rejected, (state: surveyData) => {
        state.loading = false;
        state.isSaving = false;
      })
      .addCase(updateQuestionDetail.pending, (state: surveyData) => {
        // state.loading = true;
        // do nothing
        state.isSaving = true;
      })
      .addCase(updateQuestionDetail.fulfilled, (state: surveyData, action) => {
        const feqId = action.payload.feqId;
        // state.questionObject[feqId] = {
        //   ...state.questionObject[feqId],
        //   ...action.payload
        // };
        if (action.payload.url) {
          state.questionObject[feqId].attachmentURL = action.payload.url;
        }
        if (feqId) {
          state.questionObject[feqId] = {
            ...state.questionObject[feqId],
            // ...action.payload,
            orderId: action.payload.questionSequence,
          };
          Object.keys(action.payload).forEach((key) => {
            if (action.payload[key]) {
              state.questionObject[feqId][key] = action.payload[key];
            }
          });
        }
        state.isSaving = false;
      })
      .addCase(updateQuestionDetail.rejected, (state: surveyData) => {
        // state.details = initialSurveyDetail;
        state.isSaving = false;
      })
      .addCase(deleteQuestion.pending, (state: surveyData) => {
        state.loading = true;
        // do nothing
        state.isSaving = true;
      })
      .addCase(deleteQuestion.fulfilled, (state: surveyData, action) => {
        const id = action.payload.questionId;
        let feqId = -1;
        let order = 0;
        const newQuesList = state.questionList.filter((ques) => {
          if (ques.id === id) {
            feqId = ques.feqId;
          }
          if (ques.id !== id) {
            state.questionObject[ques.feqId].orderId = order;
            state.questionObject[ques.feqId].questionSequence = order;
            order += 1;
          }
          return ques.id !== id;
        });

        // on delete get the previous question in view
        const previousQuestionIdx =
          state.questionList.findIndex((q) => q.id === id) - 1;
        state.selected =
          previousQuestionIdx < 0
            ? 'welcome'
            : state.questionList[previousQuestionIdx].feqId;

        state.questionList = newQuesList;

        if (state.questionObject[feqId]) {
          delete state.questionObject[feqId];
        }
        state.loading = false;
        state.isSaving = false;
      })
      .addCase(deleteQuestion.rejected, (state: surveyData) => {
        state.loading = false;
        state.isSaving = false;
      })
      .addCase(addQuestionText.pending, (state: surveyData) => {
        // state.loading = true;
        // do nothing
        state.isSaving = true;
      })
      .addCase(addQuestionText.fulfilled, (state: surveyData, action) => {
        const { questionId: id, textType, id: textId } = action.payload;
        if (id) {
          const question = state.questionList.find((ques) => ques.id === id);

          if (question && state.questionObject[question?.feqId]) {
            state.questionObject[question.feqId].languages =
              action.payload.languages;
          }
        }

        // In case text is for other pages, will handle them here for now
        switch (textType) {
          case 'welcome_text_main':
          case 'welcome_text_sub':
            state.messages[WELCOME_SCREEN] = action.payload.languages;
            break;
          case 'thank_you_page':
            state.messages[THANKYOU_SCREEN] = action.payload.languages;
            break;
          case 'terminate_page':
            state.messages[TERMINATE_SCREEN] = action.payload.languages;
            break;
          default:
            break;
        }

        state.isSaving = false;
      })
      .addCase(addQuestionText.rejected, (state: surveyData) => {
        state.isSaving = false;
      })
      .addCase(updateQuestionText.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(updateQuestionText.fulfilled, (state: surveyData, action) => {
        const id = action.payload.questionId;
        const textType = action.payload.textType;
        if (id) {
          const question = state.questionList.find((ques) => ques.id === id);

          if (question && state.questionObject[question?.feqId]) {
            state.questionObject[question.feqId].languages =
              action.payload.languages;
          }
        }

        // In case text is for other pages, will handle them here for now
        switch (textType) {
          case 'welcome_text_main':
          case 'welcome_text_sub':
            state.messages[WELCOME_SCREEN] = action.payload.languages;
            break;
          case 'thank_you_page':
            state.messages[THANKYOU_SCREEN] = action.payload.languages;
            break;
          case 'terminate_page':
            state.messages[TERMINATE_SCREEN] = action.payload.languages;
            break;
          default:
            break;
        }
        state.isSaving = false;
      })
      .addCase(updateQuestionText.rejected, (state: surveyData) => {
        state.isSaving = false;
      })
      .addCase(deleteQuestionText.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(deleteQuestionText.fulfilled, (state: surveyData, action) => {
        const id = action.payload.questionId;
        if (id) {
          const question = state.questionList.find((ques) => ques.id === id);

          if (question && state.questionObject[question?.feqId]) {
            state.questionObject[question.feqId].languages =
              action.payload.languages;
          }
        }
        state.isSaving = false;
      })
      .addCase(deleteQuestionText.rejected, (state: surveyData) => {
        state.isSaving = false;
      })
      .addCase(changeQuestionOrder.pending, (state: surveyData) => {
        state.loading = true;
        state.isSaving = true;
      })
      .addCase(changeQuestionOrder.fulfilled, (state: surveyData, action) => {
        const { orderId, feqId } = action.payload;
        // orderId is 1-based indexing
        const updatedQuestionIdx = orderId - 1;
        const currentQuestionIdx = state.questionList.findIndex(
          (ques) => ques.feqId === feqId,
        );

        if (feqId) {
          const _questionList: Array<questionListObjectType> = Array.from(
            state.questionList,
          );
          // move element to new index
          _questionList.splice(
            updatedQuestionIdx,
            0,
            _questionList.splice(currentQuestionIdx, 1)[0],
          );
          // set the new orderId in questionObject as well
          _questionList.forEach((ques, idx) => {
            state.questionObject[ques.feqId] = {
              ...state.questionObject[ques.feqId],
              orderId: idx,
              questionSequence: idx,
            };
          });

          state.questionList = _questionList;
        }
        state.loading = false;
        state.isSaving = false;
      })
      .addCase(changeQuestionOrder.rejected, (state: surveyData) => {
        state.loading = false;
        state.isSaving = false;
      })
      .addCase(updateJumpCondition.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(updateJumpCondition.fulfilled, (state: surveyData, action) => {
        const { condition, questionObject, feqId } = action.payload;
        if (condition) {
          state.questionObject[feqId].condition = condition;
        }
        if (questionObject) {
          state.questionObject[feqId].questionObject = questionObject;
          state.questionObject[feqId].option =
            questionObject?.list?.map((item) => item.answer) || [];
        }
        state.isSaving = false;
      })
      .addCase(removeCondition.fulfilled, (state: surveyData, action) => {
        const { condition, questionObject, feqId } = action.payload;
        if (condition) {
          state.questionObject[feqId].condition = condition;
        }
        if (questionObject) {
          state.questionObject[feqId].questionObject = questionObject;
          state.questionObject[feqId].option =
            questionObject?.list?.map((item) => item.answer) || [];
        }
        state.isSaving = false;
      })
      .addCase(addLogicExpression.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(addLogicExpression.fulfilled, (state: surveyData, action) => {
        const { condition, questionObject, feqId } = action.payload;
        if (condition) {
          state.questionObject[feqId].condition = condition;
        }
        if (questionObject) {
          state.questionObject[feqId].questionObject = questionObject;
        }
        state.isSaving = false;
      })
      .addCase(removeLogicExpression.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(removeLogicExpression.fulfilled, (state: surveyData, action) => {
        const { condition, questionObject, feqId } = action.payload;
        if (condition) {
          state.questionObject[feqId].condition = condition;
        }
        if (questionObject) {
          state.questionObject[feqId].questionObject = questionObject;
        }
        state.isSaving = false;
      })
      .addCase(addImageOptions.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(addImageOptions.fulfilled, (state: surveyData, action) => {
        const feqId = action.payload.feqId;
        if (feqId) {
          state.questionObject[feqId] = {
            ...state.questionObject[feqId],
            // ...action.payload,
            orderId: action.payload.questionSequence,
          };
          Object.keys(action.payload).forEach((key) => {
            if (action.payload[key]) {
              state.questionObject[feqId][key] = action.payload[key];
            }
          });
        }
        state.isSaving = false;
      })
      .addCase(removeMediaAttachment.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(removeMediaAttachment.fulfilled, (state: surveyData, action) => {
        const feqId = action.payload.feqId;
        state.questionObject[feqId].attachmentType = null;
        state.questionObject[feqId].attachmentURL = '';
        state.questionObject[feqId].attachmentFile = null;
        state.isSaving = false;
      })
      .addCase(addMediaAttachment.pending, (state: surveyData) => {
        state.isSaving = true;
      })
      .addCase(addMediaAttachment.fulfilled, (state: surveyData, action) => {
        const feqId = action.payload.feqId;
        // state.questionObject[feqId] = {
        //   ...state.questionObject[feqId],
        //   ...action.payload
        // };
        if (action.payload.url) {
          state.questionObject[feqId].attachmentURL = action.payload.url;
        }
        if (feqId) {
          state.questionObject[feqId] = {
            ...state.questionObject[feqId],
            // ...action.payload,
            orderId: action.payload.questionSequence,
          };
          Object.keys(action.payload).forEach((key) => {
            if (action.payload[key]) {
              state.questionObject[feqId][key] = action.payload[key];
            }
          });
        }
        state.isSaving = false;
      });
  },
});
export const {
  addQuestion,
  resetSurveyPreview,
  selectLangauge,
  surveyCleanup,
  selectQuestionScreen,
  removeQuestion,
  updateQuestionTexts,
  updateQuestionMedia,
  updateQuestionObject,
  setisValidId,
  addNewLogicCondition,
  addNewLogicExpression,
  updateAnsValueMapInText,
} = SurveySlice.actions;
export default SurveySlice.reducer;
